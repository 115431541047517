var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column flex-root"
  }, [_c('div', {
    staticClass: "d-flex flex-row-fluid bgi-size-cover bgi-position-center",
    style: {
      backgroundImage: "url(".concat(_vm.backgroundImage, ")")
    }
  }, [_c('div', {
    staticClass: "d-flex flex-row-fluid flex-column justify-content-end align-items-center text-center text-white pb-40"
  }, [_c('h1', {
    staticClass: "display-1 font-weight-bold"
  }, [_vm._v(" OOPS! ")]), _c('span', {
    staticClass: "display-4 font-weight-boldest mb-8"
  }, [_vm._v(" Unauthorized ")]), _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'dashboard'
      }
    }
  }, [_vm._v("back to dashboard")])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }